<template>
  <div>
    <div class="banner">
        <img :src="getStorage('ossurl','')+getStorage('imgs',{}).yqh" alt="" class="mainpic">
    </div>
    <md-scroll-view
          ref="scrollView"
          v-show="!isNull"
          auto-reflow
          :scrolling-x="false"
          class="scroll"
          @end-reached="loadMore">
        <div >
          <div class="activity" v-for="(item,index) in lists" :key="index">
            <div class="item">
              <div class="left">
                <img class="img" :src="ossurl+item.pichttp"/>
              </div>
              <div class="middle">
                <span>活动名称：{{item.stitle}}</span>
                <br/>
                <span>报名时间：{{item.fbtime}}</span>
                <br/>
                <span>报名人数：{{item.rennum}}</span>
              </div>
              <div class="right">
                <div class="delbtn">
                  <span class="btn btn" @click="invite(item.sno)">报名邀约</span>
                  <span class="btn btn1" @click="applymsg(item.sno)">报名信息</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more
            slot="more"
            :is-finished="loading" />
      </md-scroll-view>
    <div class="nodata" v-show="isNull">
      <img class="kongimg" src="../../../../assets/image/null.png"/>
    </div>
  </div>
</template>
<script>
import { selectPage } from '@/api/abt/customerOperation/common'
import { getStorage } from '@/lib/util'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [ loadMore ],
  data () {
    return {
      user: '',
      height: '',
      ossurl: '',
      pageNo: 1,
      size: 9,
      total: 0,
      totalPage: 0,
      loading: false,
      titlehttp: '',
      lists: [],
      listLength: '',
      isNull: true
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.height = document.body.clientHeight
    this.getData()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height - 160}px`
  },
  methods: {
    async getData (isInit = true) {
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        btagcode: '5',
        flag: '1',
        stagcode: ''
      }
      let res = await selectPage(data)
      this.lists = isInit ? res.data.data.rows : this.lists.concat(res.data.data.rows)
      if (this.lists.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },
    invite (val) {
      this.$router.push(
        { name: 'invite', query: { sno: val, empno: this.user.empno, suserid: this.user.userid } }
      )
    },
    applymsg (val) {
      this.$router.push(
        { name: 'applymsg', query: { sno: val, empno: this.user.empno, suserid: this.user.userid } }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
.scroll
  overflow-y auto
.activity
  margin auto 30px
  padding-top 30px
  padding-bottom 30px
  border-bottom 1px solid lightgrey
  .left
    width 320px
    float left
    .img
      width 270px
      height 220px
      border-radius 20px
  .middle
    span
      font-size 35px
      line-height 70px
  .right
    padding-left 500px
    .btn
      display: inline-block;
      border-radius: 0.3rem;
      font-size: 0.35rem;
      height: 0.8rem;
      line-height: 0.8rem;
      width: 2rem;
      text-align: center;
    .btn
      color: #f3a512;
      background: #f7f1e6;
      border: 1px solid #f3e3c3;
      margin-right: .2rem;
    .btn1
      color: #4194e2;
      background: #ecf3fb;
      border: 1px solid #bcdaf7;
.nodata
  padding-top 30%
  text-align center
  .kongimg
    width: 300px;
    height auto
</style>
